import { defineStore, setActivePinia } from "pinia";
import { $$language, $$t } from "@/i18n/i18n.js";
import useUserDataStore from "@/store/userDataStore.js";
import layoutConfig from "@/config/layoutConfig";
import globleConfig from "@/config/config.js";
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
function isPastEndOfDay(date) {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  const now = new Date();
  return now >= endOfDay;
}
const useCreateRenderType = defineStore({
  id: "createRenderType",
  state: () => {
    let datasC = {};
    let headSummonVisibleTime = {};
    try {
      const layoutData = window.sessionStorage.getItem(
        `${globleConfig.appname}_createLayoutData`
      );
      datasC = JSON.parse(layoutData ?? "{}");
      headSummonVisibleTime = JSON.parse(
        localStorage.getItem(
          `${globleConfig.appname}_headSummonvisible_cache_data`
        ) ?? "{}"
      );
    } catch (e) {
      datasC = {};
      headSummonVisibleTime = {};
    }

    let headSummonVisible = false;
    let headSummonVisibleCount = 0;
    if (
      !headSummonVisibleTime?.timeScamps ||
      !headSummonVisibleTime.visibleCount ||
      (isPastEndOfDay(new Date(headSummonVisibleTime?.timeScamps)) &&
        headSummonVisibleTime.visibleCount < 2)
    ) {
      localStorage.setItem(
        `${globleConfig.appname}_headSummonvisible_cache_data`,
        JSON.stringify({
          timeScamps: headSummonVisibleTime?.timeScamps ?? new Date().getTime(),
          visibleCount:
            headSummonVisibleTime?.visibleCount ?? headSummonVisibleCount,
        })
      );
      headSummonVisibleCount = headSummonVisibleTime?.visibleCount ?? 0;
      headSummonVisible = true;
    }

    const {
      curOpModule = "createEdit",
      create_createEditOpenState = "max",
      nocreate_createEditOpenState = "min",
      createEditMode = "easy",
    } = datasC;
    return {
      createRenderType: layoutConfig.renderType,
      curOpModule: curOpModule ?? "createEdit", // createEdit, createResult
      create_createEditOpenState: create_createEditOpenState ?? "max",
      nocreate_createEditOpenState: nocreate_createEditOpenState ?? "min", // max, playermax, min, playermin
      globalPlayerOpened: false,
      createEditMode: createEditMode ?? "easy", // easy hard
      headSummonVisibleTime:
        headSummonVisibleTime?.timeScamps ?? new Date().getTime(),
      headSummonVisible: headSummonVisible,
      headSummonVisibleCount: headSummonVisibleCount,
    };
  },
  actions: {
    setRenderType(renderType) {
      this.createRenderType = renderType;
    },
    setCurOpModule(curOpModule, isOpenCreate) {
      this.curOpModule = curOpModule;
      this.toggleCreateEditOpenState(isOpenCreate, true);
    },
    setCreateEditOpenState(createEditOpenState, page) {
      this[`${page}_createEditOpenState`] = createEditOpenState;
    },
    updataCreateEditOpenState(globalPlayerOpened) {
      // setPlayData call
      const isCreate = window.location.pathname.indexOf("create") > -1;
      this.globalPlayerOpened = globalPlayerOpened;
      // if (isCreate) {
      //   if (this.create_createEditOpenState != "max") {
      //     this.create_createEditOpenState = globalPlayerOpened ? "playermin" : "min";
      //   }
      // } else {
      //   if (this.nocreate_createEditOpenState != "max") {
      //     this.nocreate_createEditOpenState = globalPlayerOpened ? "playermin" : "min";
      //   }
      // }
      if (this.nocreate_createEditOpenState != "max") {
        this.nocreate_createEditOpenState = globalPlayerOpened
          ? "playermin"
          : "min";
      }
      this.create_createEditOpenState = this.nocreate_createEditOpenState;
    },
    cacheLayoutData() {
      window.sessionStorage.setItem(
        `${globleConfig.appname}_createLayoutData`,
        JSON.stringify({
          createEditMode: this.createEditMode,
          curOpModule: this.curOpModule,
          create_createEditOpenState: this.create_createEditOpenState,
          nocreate_createEditOpenState: this.nocreate_createEditOpenState,
        })
      );
    },
    initCacheLayoutData() {
      const layoutData = window.sessionStorage.getItem(
        `${globleConfig.appname}_createLayoutData`
      );
      if (layoutData) {
        const {
          curOpModule = "createEdit",
          create_createEditOpenState = "max",
          nocreate_createEditOpenState = "min",
          createEditMode = "hard",
        } = JSON.parse(layoutData ?? "{}");
        this.createEditMode = createEditMode;
        this.curOpModule = curOpModule;
        this.create_createEditOpenState = create_createEditOpenState;
        this.nocreate_createEditOpenState = nocreate_createEditOpenState;
      }
    },
    toggleCreateEditOpenState(isOpen, isSwitch) {
      if (isOpen) {
        this.nocreate_createEditOpenState = this.globalPlayerOpened
          ? "playermax"
          : "max";
      } else {
        this.nocreate_createEditOpenState = this.globalPlayerOpened
          ? "playermin"
          : "min";
      }
      this.create_createEditOpenState = this.nocreate_createEditOpenState;
      if (this.createRenderType == "mobile" && !isSwitch) {
        GlobalAudioPlayer.pause(GlobalAudioPlayer.currentUrl);
      }
    },

    setCreateEditMode(mode) {
      this.createEditMode = mode;
    },
    setHeadSummonVisible(visible) {
      this.headSummonVisible = visible;
      this.headSummonVisibleCount += 1;
      localStorage.setItem(
        `${globleConfig.appname}_headSummonvisible_cache_data`,
        JSON.stringify({
          timeScamps: new Date().getTime(),
          visibleCount: this.headSummonVisibleCount,
        })
      );
    },
  },
});

export default useCreateRenderType;
