<template>
  <div class="c-modal" v-if="pageVisible">
    <div class="mask">
      <div class="rights-box">
        <Rights v-if="rightType" :rightType="rightType" />
      </div>
      <div class="content">
        <div class="mobile-title">
          <div class="left">Log In</div>
          <img @click="closePage" src="@/assets/img/mobile/icon-close.png" alt="" />
        </div>
        <div class="close-btn" @click="closePage">
          <img src="@/assets/img/ic_close.png" alt="" />
        </div>
        <div class="flex-center bg-img">
          <div class="login-module-main login-in-main">
            <div class="login-img-box">Log In</div>
            <div class="login-in-main-content">
              <Login @submit="submit" @continueWith="continueWith" @forgotmodal="forgotmodal" />
            </div>
            <!-- <p class="forget-password-footer tc">
              Don’t have an account?
              <span @click="goSignUp" class="a-link btn pointer">Sign Up</span>
            </p> -->
            <div class="terms">
              By continuing, you agree to<a href="/terms" class="a-link btn pointer"> Terms of Use</a> and<a
                href="/privacy"
                class="a-link btn pointer"
              >
                Privacy Policy</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, readonly, reactive, watch, defineExpose, triggerRef, getCurrentInstance } from "vue"
import message from "@/components/functionCallComponent/message/message.js"
import ShowActionDialog from "@/components/functionCallComponent/action/action.js"
import Login from "@/components/login/LoginForm.vue"
import apiUser from "@/api/api/apiUser.js"
import { useRouter } from "vue-router"
import useUserDataStore from "@/store/userDataStore"
import { funComponentList } from "@/components/functionComp/index"
import useShopCartStore from "@/store/shopCartStore.js"
import { getParameterByName } from "@/utils/tools.js"
import Rights from "./rights.vue"
import { getUseSource } from "@/api/net/config.js"
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js"
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";

//自定义函数组件无法使用全局组件，需要单独引入
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  okText: {
    type: String,
    default: "确定",
  },
  handleOk: {
    type: Function, //成功回调
    default: null,
  },
  handleCancel: {
    type: Function, //失败回调
    default: null,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
  content: {
    type: String,
    default: "自定义全局函数组件......",
  },
  rightType: {
    type: String, // 'generate' | 'login' | undefined, // 显示权益的类型
  },
  create_type:{
    type: String,
    default: "2",
  },
  source: {
    type: String,
    default: "other",
  },
})

const pageVisible = ref(false)
pageVisible.value = props.visible
// 监听显示的消失，需要移除dom
watch(
  () => pageVisible.value,
  (val) => {
    !val && props.remove()
  }
)

const userDataStore = useUserDataStore()
const shopCartStore = useShopCartStore()
const createLayoutStore = useCreateLayoutStore()

const closePage = () => {
  pageVisible.value = false
}

const goSignUp = () => {
  pageVisible.value = false
  funComponentList.SignUp({
    // content: "在request.ts触发的函数式组件",
    handleOk: props.handleOk,
    handleCancel: props.handleCancel,
    rightType: props.rightType,
  })
}

const forgotmodal = () => {
  pageVisible.value = false
  funComponentList.Forgot({
    rightType: props.rightType,
    handleOk: (str) => {
      console.log("点击成功" + str)
    },
  })
}

const submit = async ({ username, email, password }) => {
  console.log("submit", email)
  firebaseUtils.logClickEvent(props.rightType === "generate" ? "newuser_login" : "login", {
    create_type: props.create_type,
    page_route: window.location.pathname,
    source: props.source
  })
  const { data, code, msg } = await apiUser.userLogin({
    email: email,
    mode: "email",
    password: password,
    source: getUseSource() ?? "",
  })
  console.log("submit==>", data, code, msg)
  if (code === 200) {
    console.log("邮箱登录成功")
    updateAuthData(data)
    // shopCartStore.getCartTotalCount()
    createLayoutStore.toggleCreateEditOpenState(true);
    typeof props.handleOk === "function" && props.handleOk(true)
    pageVisible.value = false
  } else {
    typeof props.handleCancel === "function" && props.handleCancel()
    message.error({
      maskClosable: true,
      position: "top",
      content: msg,
    })
  }
}

const continueWith = async (state) => {
  console.log("state", state)
  const { data, code, msg } = await apiUser.userLogin({
    mode: "google",
    token: state.x_auth_token,
    source: getUseSource() ?? "",
  })
  if (code === 200) {
    console.log("第三方登录成功")
    updateAuthData(data)
    shopCartStore.getCartTotalCount()
    typeof props.handleOk === "function" && props.handleOk(true)
    pageVisible.value = false
  } else {
    typeof props.handleCancel === "function" && props.handleCancel()
    message.error({
      maskClosable: true,
      position: "top",
      content: msg,
    })
  }
}

const updateAuthData = (_data) => {
  userDataStore.upDataAuthData(_data.auth)
  userDataStore.updateLoginStatus(true)
}

// onMounted(() => {
//   console.log("tipsDialog onmounted");
// });
</script>

<style lang="scss" scoped>
.c-modal {
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .mask {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
  }
  .content {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    background-image: url("@/assets/img/dialog-bg-1.webp");
    background-color: #4b4a6c;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    border-radius: 20px;
  }
}
@media screen and (max-width: 768px) {
  .mask {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 24px;
    .close-btn {
      display: none;
    }
  }
  .content {
    box-sizing: content-box;
    margin: 0;
    width: 300px;
  }
}
</style>
