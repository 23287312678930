import { defineStore, setActivePinia } from "pinia";
import { $$language, $$t } from "@/i18n/i18n.js";
import useUserDataStore from "@/store/userDataStore.js";
import layoutConfig from "@/config/layoutConfig";
import globleConfig from "@/config/config.js";
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
import useMuseDataStore from "./museDataStore.js";
import feedListApi from "../../api/api/feedListApi.js";
import message from "@/components/functionCallComponent/message.jsx";

const usePromptDataStore = defineStore({
  id: "promptDataStore",
  state: () => {
    return {
      promptData: {
        content: "",
      },
      initPresetData: true,
      promptPresetData: {
        // vocals: Array.from({ length: 3 }, (_, i) => `vocals${i + 2}`),
        // genres: Array.from({ length: 3 }, (_, i) => `genres${i + 2}`),
        // moods: Array.from({ length: 3 }, (_, i) => `moods${i + 2}`),
        // instruments: Array.from({ length: 3 }, (_, i) => `instruments${i + 2}`),
        // rhythms: Array.from({ length: 3 }, (_, i) => `rhythms${i + 2}`),
      },
      promptPresetDataShow: {
        // vocals: Array.from({ length: 2 }, (_, i) => `vocals${i}`),
        // genres: Array.from({ length: 2 }, (_, i) => `genres${i}`),
        // moods: Array.from({ length: 2 }, (_, i) => `moods${i}`),
        // instruments: Array.from({ length: 2 }, (_, i) => `instruments${i}`),
        // rhythms: Array.from({ length: 2 }, (_, i) => `rhythms${i}`),
      },
      renderKeysSorts: [
        "genres",
        "moods",
        "vocals",
        "instrumentals",
        "rhythms",
      ],
      renderPresetData: [],
      renderPresetDataHasAddData: [],
    };
  },
  actions: {
    initPromptData(data) {
      this.updatePromptData(data?.prompt ?? null);
    },
    setCurPromptData(data) {
      this.updatePromptData(data);
    },
    setPromptData(str) {
      if (this.promptData) {
        this.promptData.content = str;
      } else {
        this.promptData = {
          content: str,
        };
      }
      this.updatePromptData(this.promptData);
    },
    updatePromptData(data) {
      this.promptData = data;
      useMuseDataStore().setPromptData(data);
    },
    getPromptData() {
      return this.promptData;
    },
    deletePromptDataByAudit() {
      this.promptData = {
        content: "",
      };
    },
    getAllPromptPresetDatas(data) {
      return new Promise((resolve, reject) => {
        feedListApi
          .getPromptPresetData({
            ...data,
          })
          .then((res) => {
            if (res.code === 200) {
              this.initState = "presetData";
              this.setPromptPresetData(res.data);
              resolve();
            } else {
              reject("get prompt presetData error");
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    changebatchPromptPresetData(data) {
      return new Promise((resolve, reject) => {
        feedListApi
          .changebatchPromptPresetData({
            shown: this.promptPresetDataShow,
            remain: this.promptPresetData,
            ...data,
          })
          .then((res) => {
            let curShown = Object.values(
              this.promptPresetDataShow ?? {}
            ).reduce((res, cur) => {
              if (cur) {
                res = res.concat(cur);
              }
              return res;
            }, []);
            if (res.code === 200) {
              if (
                (!Object.values(res.data?.shown ?? {}).some((i) => i?.length) &&
                  !Object.values(res.data?.remain ?? {}).some(
                    (i) => i?.length
                  )) ||
                curShown.sort().join(",") ===
                  Object.values(res.data?.shown ?? {})
                    .reduce((res, cur) => {
                      if (cur) {
                        res = res.concat(cur);
                      }
                      return res;
                    }, [])
                    .concat(
                      Object.values(res.data?.remain ?? {}).reduce(
                        (res, cur) => {
                          if (cur) {
                            res = res.concat(cur);
                          }
                          return res;
                        },
                        []
                      )
                    )
                    .sort()
                    .join(",")

                //     ||
                // (Object.values(res.data?.shown ?? {})
                //   .reduce((res, cur) => {
                //     if (cur) {
                //       res = res.concat(cur);
                //     }
                //     return res;
                //   }, [])
                //   .sort()
                //   .join(",") === curShown.sort().join &&
                //   Object.values(res.data?.remain ?? {})
                //     .reduce((res, cur) => {
                //       if (cur) {
                //         res = res.concat(cur);
                //       }
                //       return res;
                //     }, [])
                //     .sort()
                //     .join(",") ==
                //     Object.values(this.promptPresetData ?? {})
                //       .reduce((res, cur) => {
                //         if (cur) {
                //           res = res.concat(cur);
                //         }
                //         return res;
                //       }, [])
                //       .sort())
              ) {
                message.info({
                  content: $$t("create.no_more_prompt_tags"),
                  position: "top",
                });
              } else {
                this.setPromptPresetData(res.data);
              }

              resolve();
            } else {
              reject("get batch prompt presetData error");
            }
          })
          .then(() => {
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getRemainRecommendPromptPresetData(data) {
      let res = {};
      this.renderKeysSorts.forEach((key) => {
        res[key] = (data.shown?.[key] ?? []).concat(data.remain?.[key] ?? []);
      });
      return res;
    },
    changeRecommendPromptPresetData(data) {
      let dataTime = new Date().getTime();
      this.changeRecommendPromptPresetDataTime = dataTime;
      return new Promise((resolve, reject) => {
        feedListApi
          .changeRecommendPromptPresetData({
            tags:
              this.initState == "recommendData"
                ? this.promptPresetDataShow?.genres ?? []
                : [],
            ...data,
          })
          .then((res) => {
            if (this.changeRecommendPromptPresetDataTime != dataTime) {
              reject("abandon request");
              return;
            }
            let curShown = Object.values(
              this.promptPresetDataShow ?? {}
            ).reduce((res, cur) => {
              if (cur) {
                res = res.concat(cur);
              }
              return res;
            }, []);
            if (res.code === 200) {
              if (!res.data?.tags?.length) {
                this.initState = "recommendData";
                if (
                  curShown.sort().join(",") === res.data?.tags?.sort().join(",")
                ) {
                  message.info({
                    content: $$t("create.no_more_prompt_tags"),
                    position: "top",
                  });
                }
                this.setPromptPresetData(
                  {
                    shown: res.data?.shown ?? {},
                    remain: res.data?.remain ?? {},
                  },
                  true
                );
              } else {
                this.initState = "recommendData";
                if (
                  curShown.sort().join(",") === res.data?.tags?.sort().join(",")
                ) {
                  message.info({
                    content: $$t("create.no_more_prompt_tags"),
                    position: "top",
                  });
                }
                this.setPromptPresetData(
                  {
                    shown: {
                      genres: res.data?.tags ?? [],
                    },
                    remain: this.getRemainRecommendPromptPresetData(res.data),
                  },
                  true
                );
              }

              resolve();
            } else {
              reject("get batch prompt presetData error");
            }
          })
          .then(() => {
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setPromptPresetData(data, autocomplete = false) {
      this.promptPresetDataShow = data?.shown ?? {};
      this.promptPresetData = data?.remain ?? {};
      this.renderPresetDataHasAddData = [];
      // 曲风→情绪→人声→乐器→节奏
      this.renderPresetData = this.renderKeysSorts
        .reduce((res, cur) => {
          if (this.promptPresetDataShow[cur]) {
            res = res.concat(
              this.promptPresetDataShow[cur].map((item) => {
                return {
                  content: item,
                  type: cur,
                  show: true,
                };
              })
            );
            return res;
          }
          return res;
        }, [])
        .concat(
          this.renderKeysSorts.reduce((res, cur) => {
            if (this.promptPresetData[cur]) {
              res = res.concat(
                this.promptPresetData[cur].map((item) => {
                  return {
                    content: item,
                    type: cur,
                    show: false,
                  };
                })
              );
              return res;
            }
            return res;
          }, [])
        );
      if (autocomplete) {
        let length = Object.values(this.promptPresetDataShow).reduce(
          (res, cur) => {
            return res + cur.length;
          },
          0
        );
        if (length < 10) {
          for (let i = 0; i < 10 - length; i++) {
            this.renderKeysSorts.some((key) => {
              if (this.promptPresetData[key]?.length) {
                this.promptPresetDataShow[key]?.push(
                  this.promptPresetData[key]?.shift()
                );
                return true;
              }
            });
          }
        }
      }
    },
  },
});

export default usePromptDataStore;
