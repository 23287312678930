import axiosIS from '@/api/net/axios.js';
import ApiConfig, { baseUrl } from '@/config/apiConfig.js';

// 查询用户积分
export function getCreditInfo(data = {}) {
  return axiosIS(
    `${baseUrl}/api/user/account`,
    data,
    { method: 'get' }
  );
}

// 购买积分
export function checkoutCredit(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/credit/checkout`,
    data,
    { method: 'post' }
  );
}

// 积分产品
export function getProducts(channel,data = {}) {
  return axiosIS(
    `${baseUrl}/api/payment/${channel}/products`,
    data,
    { method: 'get' }
  );
}
// 过期积分列表
export function getOrderCreditList(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/order/credit/list`,
    data,
    { method: 'get' }
  );
}

// 首购限时优惠检查
export function checkFirstPurchase(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/first-purchase/check`,
    data,
    { method: 'post' }
  );
}

// 订阅自助管理
export function manageSubscription(data = {}) {
  return axiosIS(
    `${baseUrl}/api/payment/subscription/manage`,
    data,
    { method: 'get' }
  );
}

// 用户发行数列表
export function getReleaseAmountList(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/release/amount/list`,
    data,
    { method: 'get' }
  );
}
