import { defineStore } from "pinia";
import { getSongsDetail, getSongsDetailV2 } from "@/api/api/apiStore.js";
import { $$t, $$language } from "@/i18n/i18n.js";
import message from "@/components/functionCallComponent/message/message.js";

const useStoreDetailData = defineStore({
  id: "storeDetailStoreData",
  state: () => {
    return {
      info: {},
    };
  },
  actions: {
    getInfo(query) {
      return new Promise((resolve) => {
        const api = $$language() == "en" ? getSongsDetailV2 : getSongsDetail;
        api(query).then((res) => {
          if (res.code == 200) {
            if (res?.data?.song) {
              if (!res?.data?.song?.song_url) {
                res.data.song.song_url = res.data.song.mp3_url;
              }
            }
            if (res.data?.user) {
              res.data.song.user = res.data.user;
            }
            if (res.data?.feed_id) {
              res.data.song.feed_id = res.data.feed_id;
            }
            // this.info = res?.data?.song;
          } else if (res.code == 6320){
            message.error({
              maskClosable: true,
              position: "top",
              content: $$t("common.deleted"),
            });
          }
          resolve(res);
        });
      });
    },
    updateDetail(data) {
      this.info = data;
    },
  },
});

export default useStoreDetailData;
