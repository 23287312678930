<template>
  <router-view></router-view>
</template>
<script setup>
import { watch, reactive, onMounted, ref } from "vue"
import i18n from "@/i18n/i18n.js"
import useUserDataStore from "@/store/userDataStore"
import GlobalConfig from "@/config/config.js"
import { getCookie } from "@/utils/cookie.js"
import apiUser from "@/api/api/apiUser.js"
import router from "@/router/index.js"
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js"
import useHomeDataStore from "@/store/homeDataStore.js"
import useDraftDataStore from "@/store/create/draftDataStore.js"
import useLyricDataStore from "@/store/create/lyricDataStore.js"
import tools from "@/utils/tools.js"
import { $$language, $$t } from "@/i18n/i18n.js"
import message from "@/components/functionCallComponent/message/message.js"
import { funComponentList } from "@/components/functionComp/index"
import useCreateLayoutStore from "@/store/create/createLayoutStore.js"
import { isDev } from "@/utils/tools.js"
import { getUseSource } from "@/api/net/config.js"
import { useRouter, useRoute } from "vue-router"
import { jwtDecode } from "jwt-decode"

const createLayoutStore = useCreateLayoutStore()
const Router = useRouter()
const draftDataStore = useDraftDataStore()
const lyricDataStore = useLyricDataStore()

const userDataStore = useUserDataStore()

let k_sso_token = getCookie("k_sso_token") ?? ""
const registCacheData = (_this) => {
  window.addEventListener("beforeunload", function (event) {
    const homeDataStore = useHomeDataStore()
    homeDataStore.abortSse()
    createLayoutStore.cacheLayoutData()
    if (tools.isProd() && draftDataStore.lastGenerateDraftData?.lyrics != lyricDataStore.lyric && !!lyricDataStore.lyric) {
      const confirmationMessage = "Are you sure you want to exit? Ungenerated edits will be lost?"
      event.returnValue = confirmationMessage // For most browsers
      return confirmationMessage // For some older browsers
    }
  })
  window.addEventListener("load", function (event) {
    // 页面完全加载后执行的代码
    createLayoutStore.initCacheLayoutData()
    console.log("页面已加载")
  })
}

const codeReport = async () => {
  // if ($$language() == "cn") return
  const code = tools.getParameterByName("channel") ?? ""
  console.log("code", code)
  if (!code) return
  const res = await apiUser.userCodePeport({ code: code })
  switch (res.code) {
    case 200:
      message.success({
        position: "top",
        content: $$t("common.saveSuccess"),
      })
      break
    case 6318:
      message.error({
        maskClosable: true,
        position: "top",
        content: $$t("common.linkInvalid"),
      })
      break
  }
}

const getQueryParameter = (name) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}

const getAccessToken = (url) => {
  const params = new URLSearchParams(url.split("#")[1])
  return params.get("access_token")
}

const updateAuthData = (_data) => {
  userDataStore.upDataAuthData(_data.auth)
  userDataStore.updateLoginStatus(true)
}

const loginByAccessToken = async (accessToken) => {
  const { data, code, msg } = await apiUser.userLogin({
    mode: "discord",
    token: accessToken,
    source: getUseSource() ?? "",
  })
  if (code === 200) {
    console.log("第三方登录成功")
    updateAuthData(data)
    Router.push({
      name: "create",
    })
  }
}

const loginByIdToken = async (token) => {
  const decoded = jwtDecode(token)
  console.log("decoded====>", decoded)
  const { data, code, msg } = await apiUser.userLogin({
    mode: "apple",
    extra_token: getQueryParameter("code") ?? "",
    token: decoded?.sub ?? "",
    source: getUseSource() ?? "",
  })
  if (code === 200) {
    console.log("第三方登录成功")
    updateAuthData(data)
    Router.push({
      name: "create",
    })
  }
}

const threeLoginStateGet = async () => {
  const id_token = getQueryParameter("id_token") ?? ""
  if (id_token) {
    await loginByIdToken(id_token)
    return
  }
  const accessToken = getAccessToken(window.location.href) ?? ""
  if (accessToken) {
    await loginByAccessToken(accessToken)
    return
  }

  userDataStore.init()
}

onMounted(async () => {
  registCacheData()

  const cookie = getQueryParameter("auth_key") ?? ""
  if (cookie) {
    document.cookie = `skm_session${isDev() ? "_test" : ""}=${cookie};path=/`
  }

  threeLoginStateGet()

  codeReport()

  if (GlobalConfig.appname == "YinFeng") {
    document.body.classList.add("yinfeng")
  }
})
</script>
<style lang="scss">
.lottie-animation-contaniner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .lottie-animation-content {
    height: 80px;
    width: 80px;
  }
}
</style>
