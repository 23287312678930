import axiosIS from '@/api/net/axios.js';
import ApiConfig,{ baseUrl} from '@/config/apiConfig.js';

// 商城歌曲列表
export function getShopSongs(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/shop/songs`,
    data,
    { method: 'post' }
  );
}

// 歌曲详情
// 1.04替换url(/api/pgc/shop/song/detail => /api/pgc/song/detail)
export function getSongsDetail (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/shop/song/detail`,
    data,
    { method: 'get' }
  );
}

export function getSongsDetailV2 (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/song/detail`,
    data,
    { method: 'get' }
  );
}

// 歌曲点赞
export function favoriteSong (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/user/song/favorite`,
    data,
    { method: 'post' }
  );
}

// 购物车列表
export function getCartList (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/cart/list`,
    data,
    { method: 'get' }
  );
}

// 购物车结算
export function checkoutCart(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/cart/checkout`,
    data,
    { method: 'post' }
  );
}

// 从购物车删除
export function deleteCart(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/cart/delete`,
    data,
    { method: 'post' }
  );
}

// 购物车总数
export function getCartCount (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/cart/count`,
    data,
    { method: 'get' }
  );
}

// 单个结算
export function shopCheckout (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/shop/checkout`,
    data,
    { method: 'post' }
  );
}

// 分享获取shareKey
export function reportShare (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/share/report`,
    data,
    { method: 'post' }
  );
}

// 获取what's new
export function getWhatsNew (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/news/query`,
    data,
    { method: 'get' }
  );
}

// 获取what's new等列表的阅读状态
export function getNewsReadStatus (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/news/readhistory/query`,
    data,
    { method: 'get' }
  );
}

// 设置what's new等列表的阅读状态
export function setNewsReadStatus (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/news/read/report`,
    data,
    { method: 'post' }
  );
}

export function getCreditReadStatus (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/credit/claim/state`,
    data,
    { method: 'get' }
  );
}


export function setCreditReadStatus (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/credit/claim/report`,
    data,
    { method: 'post' }
  );
}

export function checkSongReleaseStatus (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/release/check`,
    data,
    { method: 'get' }
  );
}

export function releaseSong (data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/release`,
    data,
    { method: 'post' }
  );
}

export function releaseMessageReadStatusReport(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/release/message/read`,
    data,
    { method: 'post' }
  );
}