<template>
  <div
    class="loader-audio-wave flex"
    :style="renderStyle"
    :class="`loader-audio-wave-animation--${props.animation}`"
  >
    <div class="line block"></div>
    <div class="line block rmac"></div>
    <div class="line block"></div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
} from "vue";

import { useRouter, useRoute } from "vue-router";

const Router = useRouter();
const route = useRoute();

const props = defineProps({
  animation: {
    type: Boolean,
    default: false,
  },
  size: {
    type: Object,
    default: {
      width: "20px",
      height: "20px",
    },
  },
  barConfig: {
    type: Object,
    default: () => {
      return {
        barWidth: "6px",
        barHeight: "18px",
        barSpacing: "3px",
        barColor: "#fff",
      };
    },
  },
});
const emits = defineEmits([]);
const renderData = reactive({});

const renderStyle = computed(() => {
  const { width = "20px", height = "20px", margin = "0" } = props.size;
  const {
    barWidth = "2px",
    barHeight = "12px",
    barSpacing = "3px",
    barColor = "#0057FF",
  } = props.barConfig;
  return {
    "--loadderWaveBarMargin": `${margin}`,
    "--loadderWaveWidth": `${width}`,
    "--loadderWaveHeight": `${height}`,
    "--loadderWaveBarWidth": `${barWidth}`,
    "--loadderWaveBarHeight": `${barHeight}`,
    "--loadderWaveBarSpacing": `${barSpacing}`,
    "--loadderWaveBarColor": `${barColor}`,
  };
});

onMounted(() => {});
</script>
<style lang="scss">
@keyframes audioPulse {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
.loader-audio-wave {
  margin: var(--loadderWaveBarMargin);
  align-items: end;
  justify-content: center;
  & {
    height: var(--loadderWaveHeight);
    width: var(--loadderWaveWidth);
  }
  .line {
    width: var(--loadderWaveBarWidth);
    height: var(--loadderWaveBarHeight);
    border-radius: var(--loadderWaveBarWidth);
    background: var(--loadderWaveBarColor);
    margin-bottom: 0;
    transform-origin: bottom;
  }
  &.loader-audio-wave-animation--true {
    .line {
      animation: audioPulse 0.5s infinite alternate;
    }
  }
  &.loader-audio-wave-animation--false {
    .line {
      translate: all 0.5s;
    }
  }

  .line.rmac {
    margin: 0 var(--loadderWaveBarSpacing) 0
      var(--loadderWaveBarSpacing);
  }
  .line:nth-child(1) {
    animation-delay: 0.25s;
  }
  .line:nth-child(2) {
    animation-delay: 0.5s;
  }
}
</style>
